/*** IMPORTS FROM imports-loader ***/
var define = false;

function main() {
    initNavi();
    initCookieConsent();
    initForm();
}

function initNavi() {
    var nav = document.querySelector('nav.mobile');
    var button = document.querySelector('#toggle-menu')

    button.addEventListener("click", function () {
        if (nav.classList.contains('active')) {
            button.style.backgroundImage = "url(/dist/img/hamburger-menu@2x.png)";
            nav.classList.remove('active');
        } else {
            button.style.backgroundImage = "url(/dist/img/hamburger-close@2x.png)";
            nav.classList.add('active');
        }
    });
}

function initForm() {
    if (document.querySelector('form') === null) { return; }

    var form = document.querySelector(('form'));
    form.addEventListener('submit', submitForm);
}

function submitForm(event) {
    event.preventDefault();
    var form = document.querySelector(('form'));
    var http = new XMLHttpRequest();
    var url = '/cfg/sendmail.php';

    var data = new FormData(form);

    http.open('POST', url, true);


    http.onreadystatechange = function() {
        if(http.readyState == 4 && http.status == 200) {
            document.querySelector(('#form-response')).innerHTML = http.responseText;
            document.querySelector(('form')).reset();
        } else {
            console.log(http.response)
        }
    }
    http.send(data);
}

function initCookieConsent() {
    var cookieBar = document.querySelector('.cookieBar');
    if (!cookieBar) { return };
    var accept = document.querySelector('.cookieBar #accept'),
        decline = document.querySelector('.cookieBar #decline');

    if(getCookie('cookie_consent') == 1) {
        loadGAonConsent();
        return;
    } else if(getCookie('cookie_consent') == 2) {
       return;
    } else {
        setTimeout(function () {
            cookieBar.classList.add('fade-in');
        }, 800);

        accept.addEventListener("click", function () {
            loadGAonConsent();
            setCookie('cookie_consent', 1, 365);
            setTimeout(function () {
                cookieBar.classList.remove('fade-in');
            }, 300);
        });

        decline.addEventListener("click", function () {
            setCookie('cookie_consent', 2, 1);
            setTimeout(function () {
                cookieBar.classList.remove('fade-in');
            }, 300);
        });}
}

function loadGAonConsent(){
    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    ga('create', 'UA-2986941-4', 'auto');
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview');
    var gascript = document.createElement("script");
    gascript.async = true;
    gascript.src = "https://www.googletagmanager.com/gtag/js?id=UA-2986941-4";
    document.getElementsByTagName("head")[0].appendChild(gascript, document.getElementsByTagName("head")[0]);
}

main();

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

